import Vue from 'vue'
import VueRouter from 'vue-router'
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/index',
    name: 'menuFrame',
    component: () => import('../views/menuFrame.vue'),
    redirect: '/index/messageAdminister',
    children: [
      {
        path: 'messageAdminister',
        name: 'messageAdminister',
        component: () => import('../views/messageAdminister/messageAdminister.vue'),
      },
      {
        path: 'messageAdministerIsEditor',
        name: 'messageAdminister',
        component: () => import('../views/messageAdminister/messageAdministerEditor.vue'),
      },
      {
        path: 'applyAdminister',
        name: 'applyAdminister',
        component: () => import('../views/applyAdminister/applyAdminister.vue'),
      },
      {
        path: 'applyAdministerIsEditor',
        name: 'applyAdministerIsEditor',
        component: () => import('../views/applyAdminister/applyAdministerIsEditor.vue'),
      },
      {
        path: 'mapAdminister',
        name: 'mapAdminister',
        component: () => import('../views/mapAdminister/mapAdminister.vue'),
      },
      {
        path: 'accountAuthority',
        name: 'accountAuthority',
        component: () => import('../views/accountAuthority/accountAuthority.vue'),
      },
      {
        path: 'accountAuthorityIsEditor',
        name: 'accountAuthorityIsEditor',
        component: () => import('../views/accountAuthority/accountAuthorityEditor.vue'),
      },
      {
        path: 'dataAdministerPOI',
        name: 'dataAdministerPOI',
        component: () => import('../views/dataAdministerPOI/dataAdministerPOI.vue'),
      },
      {
        path: 'dataAdministerPOIIsList',
        name: 'dataAdministerPOIList',
        component: () => import('../views/dataAdministerPOI/dataAdministerPOIList.vue'),
      },
      {
        path: 'dataAdministerPOIIsDrawer',
        name: 'dataAdministerPOIIsDrawer',
        component: () => import('../views/dataAdministerPOI/dataAdministerPOIDrawer.vue'),
      },
      {
        path: 'dataAdministerGrid',
        name: 'dataAdministerGrid',
        component: () => import('../views/dataAdministerGrid/dataAdministerGrid.vue'),
      },
      {
        path: 'dataAdministerGridIsDrawer',
        name: 'dataAdministerGridIsDrawer',
        component: () => import('../views/dataAdministerGrid/dataAdministerGridDrawer.vue'),
      },
      {
        path: 'deviceAdminister',
        name: 'deviceAdminister',
        component: () => import('../views/deviceAdminister/deviceAdminister.vue'),
      },
      {
        path: 'deviceAdministerIsList',
        name: 'deviceAdministerList',
        component: () => import('../views/deviceAdminister/deviceAdministerList.vue'),
      },
      {
        path: 'deviceAdministerIsDrawer',
        name: 'deviceAdministerIsDrawer',
        component: () => import('../views/deviceAdminister/deviceAdministerDrawer.vue'),
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
