import axios from '@/utils/axios'
const api = {
  login: (params) => axios.post(`/navigate/manage/login`, params),
  quit: (params) => axios.post(`/navigate/manage/quit`, params),
  accountList: (params) => axios.post(`/navigate/manage/accountSelectByPage`, params),
  accountSelectById: (params) => axios.post(`/navigate/manage/accountSelectById`, params),
  accountInsert: (params) => axios.post(`/navigate/manage/accountInsert`, params),
  accountUpdate: (params) => axios.post(`/navigate/manage/accountUpdate`, params),
  menuSelectAll: (params) => axios.post(`/navigate/manage/menuSelectAll`, params),
  customerSelectAll: (params) => axios.post(`/navigate/manage/customerSelectAll`, params),
  customerSelectListByPage: (params) => axios.post(`/navigate/manage/customerSelectListByPage`, params),
  customerSelectById: (params) => axios.post(`/navigate/manage/customerSelectById`, params),
  customerInsert: (params) => axios.post(`/navigate/manage/customerInsert`, params),
  customerUpdate: (params) => axios.post(`/navigate/manage/customerUpdate`, params),
  customerDelete: (params) => axios.post(`/navigate/manage/customerDelete`, params),
  customerSelectCompanyNature: (params) => axios.post(`/navigate/manage/customerSelectCompanyNature`, params),
  customerSelectCompanyIndustry: (params) => axios.post(`/navigate/manage/customerSelectCompanyIndustry`, params),
  customerSelectContactPosition: (params) => axios.post(`/navigate/manage/customerSelectContactPosition`, params),
  applyList: (params) => axios.post(`/navigate/manage/applySelectByPage`, params),
  applySelectById: (params) => axios.post(`/navigate/manage/applySelectById`, params),
  applyInsert: (params) => axios.post(`/navigate/manage/applyInsert`, params),
  applyUpdate: (params) => axios.post(`/navigate/manage/applyUpdate`, params),
  
  mapPackList: (params) => axios.post(`/navigate/manage/mapPackSelectAll`, params),
  mapPackInsert: (params) => axios.post(`/navigate/manage/mapPackInsert`, params),
  mapPackDelete: (params) => axios.post(`/navigate/manage/mapPackDelete`, params),
  mapBackList: (params) => axios.post(`/navigate/manage/mapPackSelectById`, params),
  lastPosition: (params) => axios.post(`/navigate/manage/mapPackSelectLastPosition`, params),
  mapBackUpdate: (params) => axios.post(`/navigate/manage/mapPackUpdate`, params),

  mapDelete: (params) => axios.post(`/navigate/manage/mapDetailDelete`, params),
  mapInsert: (params) => axios.post(`/navigate/manage/mapDetailInsert`, params),
  mapDetail: (params) => axios.post(`/navigate/manage/mapDetailSelectById`, params),
  mapUpdate: (params) => axios.post(`/navigate/manage/mapDetailUpdate`, params),
  mapDetailUpdate: (params) => axios.post(`/navigate/manage/mapDetailUpdateStatus`, params),
  
  poiList: (params) => axios.post(`/navigate/manage/POISelectListByPage`, params),
  poiListAll: (params) => axios.post(`/navigate/manage/POISelectAll`, params),
  floorListAll: (params) => axios.post(`/navigate/manage/floorSelectListByMapPackId`, params),
  floorDetail: (params) => axios.post(`/navigate/manage/mapDetailSelectByIds`, params),
  mapBackPoiType: (params) => axios.post(`/navigate/manage/POITypeSelectByMapPackId`, params),
  poiTypeAll: (params) => axios.post(`/navigate/manage/POITypeSelectAll`, params),
  poiTypeUpdate: (params) => axios.post(`/navigate/manage/mapPackPOITypeEdit`, params),
  poiInsert: (params) => axios.post(`/navigate/manage/POIInsert`, params),
  poiUpdate: (params) => axios.post(`/navigate/manage/POIUpdate`, params),
  poiRelease: (params) => axios.post(`/navigate/manage/batchRelease`, params),
  poiDelete: (params) => axios.post(`/navigate/manage/POIDelete`, params),
  POIUpdateStatus: (params) => axios.post(`/navigate/manage/POIUpdateStatus`, params),
  POISelectById: (params) => axios.post(`/navigate/manage/POISelectById`, params),


  obstructionEdit: (params) => axios.post(`/navigate/manage/obstructionEdit`, params),
  obstructionSelectByFloorId: (params) => axios.post(`/navigate/manage/obstructionSelectByFloorId`, params),
  obstructionDelete: (params) => axios.post(`/navigate/manage/obstructionDelete`, params),
  obstructionUpdateStatus: (params) => axios.post(`/navigate/manage/obstructionUpdateStatus`, params),
  obstructionBatchRelease: (params) => axios.post(`/navigate/manage/obstructionBatchRelease`, params),
  selectObstructionTypeList: (params) => axios.post(`/navigate/manage/selectObstructionTypeList`, params),
  


  selectEquipmentTypeList: (params) => axios.post(`/navigate/manage/selectEquipmentTypeList`, params),
  selectEquipmentListByPage: (params) => axios.post(`/navigate/manage/selectEquipmentListByPage`, params),
  updateEquipment: (params) => axios.post(`/navigate/manage/updateEquipment`, params),
  deleteEquipment: (params) => axios.post(`/navigate/manage/deleteEquipment`, params),
  selectEquipmentListByEquipmentTypeId: (params) => axios.post(`/navigate/manage/selectEquipmentListByEquipmentTypeId`, params),
  selectEquipmentById: (params) => axios.post(`/navigate/manage/selectEquipmentById`, params),
  selectEquipmentAllList: (params) => axios.post(`/navigate/manage/selectEquipmentAllList`, params),
  selectPointOfInterestListByPlat: (params) => axios.post(`/navigate/manage/selectPointOfInterestListByPlat`, params),
  insertEquipment: (params) => axios.post(`/navigate/manage/insertEquipment`, params),
  updateEquipment: (params) => axios.post(`/navigate/manage/updateEquipment`, params),
  
  
  
  

  convertAll: (params) => axios.AlgorithmPost(`/convert/convert_all`, params),
  splitGrid: (params) => axios.AlgorithmPost(`/convert/split_grid`, params),
  polygonToGrid: (params) => axios.AlgorithmPost(`/convert/polygon_to_grid`, params), // 多边形转换网格码
}

export default (Vue) => {
    Vue.prototype.$api = api
}
