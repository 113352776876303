import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

import './utils/rem'
import './assets/css/reset.css'
import api from './utils/api'
Vue.use(api)

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import * as L from "leaflet";
import "leaflet/dist/leaflet.css";
Vue.L = Vue.prototype.$L = L;


import elementPublic from '@/utils/elementPublic'
Vue.prototype.$startLoading = elementPublic.startLoading // loading'显示
Vue.prototype.$endLoading = elementPublic.endLoading // loading 隐藏
Vue.prototype.$errorMessage = elementPublic.errorMessage;// 失败提示
Vue.prototype.$successMessage = elementPublic.successMessage;//成功提示

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
