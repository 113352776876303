import axios from 'axios'
import router from '@/router'
import {
  Message
} from 'element-ui';

// 环境的切换
let ManageURL = ''
let AlgorithmURL = ''
if (process.env.NODE_ENV == 'development') {
  // ManageURL = 'http://192.168.0.141:10011';
  ManageURL = 'https://navigatemanagetest.biswitnc.com';
  AlgorithmURL = 'https://navigatealgorithmtest.biswitnc.com';
} else if (process.env.NODE_ENV == 'test') {
  ManageURL = 'https://navigatemanagetest.biswitnc.com';
  AlgorithmURL = 'https://navigatealgorithmtest.biswitnc.com';
} else if (process.env.NODE_ENV == 'production') {
  ManageURL = 'https://navigatemanage.langtuvison.com';
  AlgorithmURL = 'https://navigatealgorithm.langtuvison.com';
}

const service = axios.create({
  // baseURL,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
})

// 请求拦截器
service.interceptors.request.use(
 
  config => {
    if (localStorage.getItem('Authorization')) {
      config.headers.token = localStorage.getItem('Authorization');
    }
    return config;
  },
  error => {
    return Promise.reject(error);
});

// 响应拦截器
service.interceptors.response.use(
  res => {
    const status = res.data.code;
    if (status && status !== 1000) {
      Message({
        message: res.data.msg,
        type: "error"
      });
    }
    if (status === 1005) {
      router.push('/login')
    }
    return res;
  }, error => {
    return Promise.reject(error);
});

export default {
  ...service,
  //改写get传参方式,去掉params前缀
  get (url, data) {
    return service({
      url,
      data,
      method:'get'
    })
  },
  post (url, data) {
    return service({
      url: `${ManageURL}${url}`,
      data,
      method:'post'
    })
  },
  AlgorithmPost (url, data) {
    return service({
      url: `${AlgorithmURL}${url}`,
      data,
      method:'post'
    })
  },
}