
import {  Loading ,Message } from 'element-ui'; //项目已经全局引入element的话可以不单独引入

let loading        //定义loading变量
// 显示loading
function startLoading(text) {    //使用Element loading-start 方法
    loading = Loading.service({
        lock: true,
        text: text || 'loading…',
        spinner: 'el-icon-loading', // 自定义加载图标类名
        background: 'rgba(0,0,0,0.5)'
    })
}
// 关闭loading
function endLoading() {    //使用Element loading-close 方法
    if(loading){
        loading.close()
    }
}


// 失败信息弹框
const errorMessage = (text) => {
  Message.error(text)
}
// 成功信息弹框
const successMessage = (text) => {
  Message.success(text)
}


export default { startLoading,endLoading,errorMessage,successMessage};

